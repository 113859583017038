export const FEATURED_CARDS_CATEGORIES_MOCK = [
  {
    id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
    landing_page_name: 'Customer Stories',
    navigation_name: 'Customer Stories',
    title: 'Customer Stories',
    url_segment: 'customer-stories',
  },
  {
    id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
    landing_page_name: 'Product Releases',
    navigation_name: 'Product Releases',
    title: 'Product Releases',
    url_segment: 'product-releases',
  },
  {
    id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
    landing_page_name: 'Newsroom',
    navigation_name: 'Newsroom',
    title: 'Newsroom',
    url_segment: 'newsroom',
  },
];

export const FEATURED_CARDS_MOCK: any = [
  {
    publication_date: 'February 03, 2023',
    id: 'c3936e59-1028-599e-89ee-18701b856fa0',
    uid: 'blta4cf7605bde8630c',
    title: 'How Legal Tech Improves Law Firm Client Experience',
    page_url: 'how-legal-tech-improves-law-firm-client-experience',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=1064&height=708&format=webp&quality=50 1064w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=2128&height=1416&format=webp&quality=50 2128w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=4256&height=2832&format=webp&quality=50 4256w',
                  sizes: '(min-width: 4256px) 4256px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=4256&height=2832&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=1064&height=708&format=jpg&quality=50 1064w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=2128&height=1416&format=jpg&quality=50 2128w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltca2225aa52cc0e5b/63dbd49d11a22c0d982326dc/Family_Law_(2).jpeg?width=4256&height=2832&format=jpg&quality=50 4256w',
                sizes: '(min-width: 4256px) 4256px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4256,
            height: 2832,
          },
        },
        image_alt_text: 'Legal Tech Improves Client Experiences',
      },
    },
    headline: 'How Legal Tech Improves Law Firm Client Experience ',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Investing in legal tech can help you streamline your entire practice. See how legal tech can improve your firm’s client experiences. ',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'January 27, 2023',
    id: '4ac4efd4-e0fa-5e84-a0ca-90d3a9536f02',
    uid: 'blt7a5a1edb6241da7f',
    title: 'Cloud Adoption Can Boost The Bottom Line For Legal Firms, Too',
    page_url: 'law-firm-cloud-adoption',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=240&height=135&format=webp&quality=50 240w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=480&height=270&format=webp&quality=50 480w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=960&height=540&format=webp&quality=50 960w',
                  sizes: '(min-width: 960px) 960px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=960&height=540&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=240&height=135&format=jpg&quality=50 240w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=480&height=270&format=jpg&quality=50 480w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt56ad34209b42a930/63d41d7fc31a7126813ff855/0x0.jpg?width=960&height=540&format=jpg&quality=50 960w',
                sizes: '(min-width: 960px) 960px, 100vw',
              },
            },
            layout: 'constrained',
            width: 960,
            height: 540,
          },
        },
        image_alt_text: 'Woman Attorney Working',
      },
    },
    headline: 'Cloud Adoption Can Boost The Bottom Line For Legal Firms, Too',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Four practical reasons to consider switching to the cloud. See how migrating to the cloud improves the bottom line and cuts business costs. ',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'January 20, 2023',
    id: '095cb1cb-bb10-5ab7-8e80-61e7d04b3817',
    uid: 'blta6538e09777f381c',
    title: 'Five Self-Inflicted Wounds That Hurt Law Firms’ Bottom Line',
    page_url: 'law-firms-bottom-line',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt003878a81f70bb7c/63c97d11eaf215108cb67a55/Five-self-inflicted-wounds-that-hurt-law-firms-bottom-line.webp',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt003878a81f70bb7c/63c97d11eaf215108cb67a55/Five-self-inflicted-wounds-that-hurt-law-firms-bottom-line.webp?width=313&height=176&format=webp&quality=50 313w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt003878a81f70bb7c/63c97d11eaf215108cb67a55/Five-self-inflicted-wounds-that-hurt-law-firms-bottom-line.webp?width=625&height=352&format=webp&quality=50 625w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt003878a81f70bb7c/63c97d11eaf215108cb67a55/Five-self-inflicted-wounds-that-hurt-law-firms-bottom-line.webp?width=1250&height=703&format=webp&quality=50 1250w',
                  sizes: '(min-width: 1250px) 1250px, 100vw',
                  type: 'image/webp',
                },
              ],
            },
            layout: 'constrained',
            width: 1250,
            height: 703,
          },
        },
        image_alt_text: '',
      },
    },
    headline: 'Five Self-Inflicted Wounds That Hurt Law Firms’ Bottom Line',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      "Five things that hurt a law firm's bottom line and a few simple steps that can address these quickly and painlessly.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#028378',
      },
    },
  },
  {
    publication_date: 'January 13, 2023',
    id: '46c9aa14-f3b1-51db-adb5-6370de716e55',
    uid: 'blt444c88bbd0763093',
    title: 'The law firms of the 2050s: Will we even recognize them?',
    page_url: 'law-firms-of-the-future',
    key_quote:
      'The best approach is to plan based on what we know but remain flexible enough to change course if history surprises us yet again.',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=2082&height=1000&format=webp&quality=50 2082w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=4164&height=2000&format=webp&quality=50 4164w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=8328&height=4000&format=webp&quality=50 8328w',
                  sizes: '(min-width: 8328px) 8328px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=8328&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=2082&height=1000&format=jpg&quality=50 2082w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=4164&height=2000&format=jpg&quality=50 4164w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42d50078c4e2e54c/63c1754d5291cc643f1f11ba/AdobeStock_314728509.jpeg?width=8328&height=4000&format=jpg&quality=50 8328w',
                sizes: '(min-width: 8328px) 8328px, 100vw',
              },
            },
            layout: 'constrained',
            width: 8328,
            height: 4000.0000000000005,
          },
        },
        image_alt_text: '',
      },
    },
    headline: 'The Law Firms of the 2050s: Will we even recognize them?',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'It’s time for the legal profession to start planning for deployments of people, facilities, and resources that make more sense for where the whole world—not just the legal profession—is going.',
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'January 04, 2023',
    id: '48a6acbc-df14-520f-aa11-e21f96d41816',
    uid: 'bltf8143b7e4433fbba',
    title: 'How Legal Tech Can Help Reduce Burnout in Law Firms',
    page_url: '4-ways-reduce-burnout-legal-tech',
    key_quote:
      "The most significant problem Neos accomplished is the ability to use the software from anywhere entirely. My employees work from the office or their homes seamlessly. Features such as document management and Outlook integration have revolutionized our law office's efficiency.",
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'How Legal Tech Can Help Reduce Burnout in Law Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Four ways law firms can reduce employee burnout through the correct application of cutting-edge legal technology.',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Hybrid & Remote Work',
      },
      {
        display_name: 'Legal Trends',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: '',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'December 27, 2022',
    id: 'bb7f8247-624a-5996-8d93-ddd30d1173ae',
    uid: 'blt00a8dc2d437bf5c9',
    title: 'Neos 2022 Feature Roundup',
    page_url: 'whats-new/feature-roundup-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=webp&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=webp&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=webp&quality=50 2559w',
                  sizes: '(min-width: 2559px) 2559px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=png&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=png&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50 2559w',
                sizes: '(min-width: 2559px) 2559px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2559,
            height: 1638,
          },
        },
        image_alt_text: '',
      },
    },
    headline: 'Neos 2022 Feature Roundup',
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      'Check out the recap of all features released in 2022 for cloud-based legal case management software Neos.',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'December 27, 2022',
    id: 'ce6891d3-bb63-5d05-9e0e-6f457cb7170b',
    uid: 'blt26a4966d9ab5ac18',
    title: "What's New: Neos December 2022",
    page_url: 'whats-new/december-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=webp&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=webp&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=webp&quality=50 2559w',
                  sizes: '(min-width: 2559px) 2559px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=png&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=png&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50 2559w',
                sizes: '(min-width: 2559px) 2559px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2559,
            height: 1638,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos December 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the latest product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'December 22, 2022',
    id: 'e99dd3d4-cbf0-5673-9bdd-96fec0b1e76a',
    uid: 'blt40c0769b38669981',
    title: 'Assembly Software 2022 Year in Review',
    page_url: 'assembly-software-2022',
    key_quote:
      'Neos is now the premier cloud-based product in the practice management industry. Our customers’ engagement, feedback, and testimonials have been a tremendous complement in achieving this and validating the tribute.  Thank you very much to everyone who has played an integral role in enhancing Assembly’s brand identity and shaping our products and services offering, all of which has led to the strategic transformation of a world-class technology software platform for the legal community.',
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "Assembly's 2022 Year in Review",
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Top highlights that made 2022 such a fantastic year for Assembly, from the innovative features we added to Neos, to the industry accolades won, and our comprehensive company rebranding to Assembly Software',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: '',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'December 01, 2022',
    id: '07386ff2-58b8-5889-b56f-c8c0db65b7ec',
    uid: 'bltf43f32fc53232026',
    title: '5 Legal Tech Trends to Look Out For in 2023',
    page_url: 'five-legal-tech-trends-2023',
    key_quote:
      'Legal technology spending will increase to approximately 12% of in-house budgets by 2025, a threefold increase from 2020',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=1477&height=985&format=webp&quality=50 1477w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=2953&height=1969&format=webp&quality=50 2953w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=5906&height=3937&format=webp&quality=50 5906w',
                  sizes: '(min-width: 5906px) 5906px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=5906&height=3937&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=1477&height=985&format=jpg&quality=50 1477w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=2953&height=1969&format=jpg&quality=50 2953w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3cf5f8779004b4d5/6390c39872f47d5e1dfab5ef/AdobeStock_551291180.jpeg?width=5906&height=3937&format=jpg&quality=50 5906w',
                sizes: '(min-width: 5906px) 5906px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5906,
            height: 3937,
          },
        },
        image_alt_text: '2023 Legal Tech Trends',
      },
    },
    headline: '5 Legal Tech Trends to Look Out For in 2023',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      "Key legal tech trends you can expect to see in the legal technology market in 2023 and how they'll affect law firms throughout the United States",
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'November 23, 2022',
    id: '16e61248-6d49-559d-bb0d-88061574a70e',
    uid: 'blt6f76991c0d90192d',
    title: "What's New: Neos November 2022",
    page_url: 'whats-new/november-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=webp&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=webp&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=webp&quality=50 2559w',
                  sizes: '(min-width: 2559px) 2559px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=png&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=png&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50 2559w',
                sizes: '(min-width: 2559px) 2559px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2559,
            height: 1638,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos November 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the latest product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'November 21, 2022',
    id: '468c495d-2f65-5055-855b-dfbbdf2ee75b',
    uid: 'blt1359bfdb4477a8c1',
    title:
      "Legal Tech Publishing’s Practice Management by Practice Area Buyer's Guide: Neos Excerpt",
    page_url: 'practice-management-practice-area-buyers-guide',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=1932&height=809&format=webp&quality=50 1932w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=3863&height=1618&format=webp&quality=50 3863w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=7726&height=3235&format=webp&quality=50 7726w',
                  sizes: '(min-width: 7726px) 7726px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=7726&height=3235&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=1932&height=809&format=png&quality=50 1932w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=3863&height=1618&format=png&quality=50 3863w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc8eff2c462048866/638e426a64be6d77c9a808f6/Buyers_Guide_Spread.png?width=7726&height=3235&format=png&quality=50 7726w',
                sizes: '(min-width: 7726px) 7726px, 100vw',
              },
            },
            layout: 'constrained',
            width: 7726,
            height: 3235,
          },
        },
        image_alt_text: 'practice management buyers guide cover',
      },
    },
    headline:
      "Legal Tech Publishing’s Practice Management by Practice Area Buyer's Guide: Neos Excerpt ",
    lc_content_type: [
      {
        url_segment: 'brochures',
        navigation_name: 'Product Brochures',
        id: 'a1fec80a-abcf-5042-88a1-318775894ce5',
      },
    ],
    teaser_text:
      "Neos, Assembly's cloud-based practice management system, is proud to be featured as a premier provider in Legal Tech Publishing’s newly released Practice Management by Practice Area Buyer's Guide.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'November 15, 2022',
    id: '2b5f942a-3081-575b-b774-9d4de5c7d0ca',
    uid: 'blt300a79eaf2672d23',
    title: 'Case Study: James Morris Law',
    page_url: 'james-morris-law',
    key_quote:
      '"Neos helps our team save so much time! Between generating documents (from pre-saved templates) to seamless Outlook email and calendar integrations, we are more productive than ever."',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=627&height=418&format=webp&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=1254&height=836&format=webp&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=2508&height=1672&format=webp&quality=50 2508w',
                  sizes: '(min-width: 2508px) 2508px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=2508&height=1672&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=627&height=418&format=jpg&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=1254&height=836&format=jpg&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt42e53dd84db6faf2/636d5f3e7487894eca7dce6d/Family_Law.jpeg?width=2508&height=1672&format=jpg&quality=50 2508w',
                sizes: '(min-width: 2508px) 2508px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2508,
            height: 1672,
          },
        },
        image_alt_text: 'Men and women on the laptop',
      },
    },
    headline:
      'Neos Helps James Morris Law Increase Productivity and Flexibility While Closing More Cases with Ease',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text:
      '"Neos helps our team save so much time! Between generating documents (from pre-saved templates) to seamless Outlook email and calendar integrations, we are more productive than ever." ',
    customer: 'James Morris Law',
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=85&height=19&format=webp&quality=50 85w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=170&height=38&format=webp&quality=50 170w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=webp&quality=50 340w',
                  sizes: '(min-width: 340px) 340px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=85&height=19&format=png&quality=50 85w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=170&height=38&format=png&quality=50 170w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=png&quality=50 340w',
                sizes: '(min-width: 340px) 340px, 100vw',
              },
            },
            layout: 'constrained',
            width: 340,
            height: 75,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png',
        },
        image_alt_text: 'James Morris Law',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'November 15, 2022',
    id: 'bada5ec2-0c88-52af-b7b4-e2e71925d349',
    uid: 'bltd43dfe29c7f88fe6',
    title: 'Top 5 Security Benefits of Cloud-Based Case Management Software',
    page_url: 'top-5-security-benefits-of-cloud-based-case-management-software',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=627&height=418&format=webp&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=1254&height=836&format=webp&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=2508&height=1672&format=webp&quality=50 2508w',
                  sizes: '(min-width: 2508px) 2508px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=2508&height=1672&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=627&height=418&format=jpg&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=1254&height=836&format=jpg&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0909cec790effb36/6320a6a2fac7b2660d9fe3cf/AdobeStock_436218001.jpeg?width=2508&height=1672&format=jpg&quality=50 2508w',
                sizes: '(min-width: 2508px) 2508px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2508,
            height: 1672,
          },
        },
        image_alt_text: 'Cloud technology graphic',
      },
    },
    headline: 'Top 5 Security Benefits of Cloud-Based Case Management Software',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Learn more about cloud-based case management software and the facts about security in the cloud.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Security',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'November 10, 2022',
    id: '14971e83-8675-5910-b371-bebf901eaf91',
    uid: 'blt68e7d3262c942581',
    title:
      'For Security-Conscious Law Firms, the Cloud Is a Better Option Than Ever Before',
    page_url: 'law-firm-cloud-security',
    key_quote:
      'Even top government agencies like the CIA and FBI are using the cloud, so it should be secure enough for your law firm to use it, too.',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=1250&height=653&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=2500&height=1307&format=webp&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=5000&height=2613&format=webp&quality=50 5000w',
                  sizes: '(min-width: 5000px) 5000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=5000&height=2613&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=1250&height=653&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=2500&height=1307&format=jpg&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=5000&height=2613&format=jpg&quality=50 5000w',
                sizes: '(min-width: 5000px) 5000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5000,
            height: 2613,
          },
        },
        image_alt_text: '',
      },
    },
    headline:
      'For Security-Conscious Law Firms, the Cloud Is a Better Option Than Ever',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      "Six reasons firms can trust the security of today's cloud providers",
    customer: null,
    topics: [
      {
        display_name: 'Security',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'November 10, 2022',
    id: '2afc1939-1001-576b-8243-31f94fcf55a3',
    uid: 'blt1454e4c77b88be10',
    title: 'Looking to the Future of Legal Tech with Jim Garrett',
    page_url: 'looking-to-the-future-of-legal-tech-podcast',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Looking to the Future of Legal Tech with Jim Garrett',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      "Spotlight Branding is joined by Assembly Software's CTO, Jim Garrett, to discuss the current state of legal tech adoption and what the future holds for the legal industry.",
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'In the News',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'October 23, 2022',
    id: 'd1c4583a-f9f6-59b4-8098-1756b18eb0a9',
    uid: 'blta9492041ddd16357',
    title: "What's New: Neos October 2022",
    page_url: 'whats-new/october-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=webp&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=webp&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=webp&quality=50 2559w',
                  sizes: '(min-width: 2559px) 2559px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=png&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=png&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50 2559w',
                sizes: '(min-width: 2559px) 2559px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2559,
            height: 1638,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos October 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the latest product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'October 06, 2022',
    id: 'c8804c99-317f-51fc-ac62-bd8fabf771d0',
    uid: 'bltdd8d584466543501',
    title:
      'Legal Talk Network Podcast: Better Law Firm Intake Management & Craft Brewing',
    page_url: 'better-law-firm-intake-management',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'Legal Toolkit Podcast: Better Law Firm Intake Management & Craft Brewing',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      "McKay and Jared Correia, host of Legal Talk Network's Legal Toolkit podcast, discuss modern intake platforms for law firms and craft brewing (a fun but unlikely combination!). ",
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'In the News',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'September 30, 2022',
    id: 'ac94648e-f784-52de-91ef-874470a798a7',
    uid: 'blta47ec2ed3761640a',
    title: 'The top-rated and most exciting Neos features released in Q3 2022',
    page_url: 'q3-roundup',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=300&height=200&format=webp&quality=50 300w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=600&height=400&format=webp&quality=50 600w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=1200&height=800&format=webp&quality=50 1200w',
                  sizes: '(min-width: 1200px) 1200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=1200&height=800&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=300&height=200&format=jpg&quality=50 300w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=600&height=400&format=jpg&quality=50 600w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=1200&height=800&format=jpg&quality=50 1200w',
                sizes: '(min-width: 1200px) 1200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 1200,
            height: 800,
          },
        },
        image_alt_text: 'people looking at a whiteboard',
      },
    },
    headline:
      'The top-rated and most exciting Neos features released in Q3 2022',
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text: 'Learn about the robust features we rolled out in Q3 2022.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: null,
        image_alt_text: '',
      },
      card_color: {
        colors: null,
      },
    },
  },
  {
    publication_date: 'September 27, 2022',
    id: 'f77563a1-4429-50d1-88f3-689255f23726',
    uid: 'blt1b8d0d02c2d2497c',
    title: 'Six Tips to Successful Inbound Marketing for Law Firm',
    page_url: 'inbound-marketing-for-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=1368&height=912&format=webp&quality=50 1368w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=2736&height=1824&format=webp&quality=50 2736w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=5472&height=3648&format=webp&quality=50 5472w',
                  sizes: '(min-width: 5472px) 5472px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=5472&height=3648&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=1368&height=912&format=jpg&quality=50 1368w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=2736&height=1824&format=jpg&quality=50 2736w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb490bbf538caff6c/62a0c821c4d59b57e0c23124/AdobeStock_323607627.jpeg?width=5472&height=3648&format=jpg&quality=50 5472w',
                sizes: '(min-width: 5472px) 5472px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5472,
            height: 3648,
          },
        },
        image_alt_text: 'Content Marketing for Law Firms',
      },
    },
    headline: 'Six Tips to Successful Inbound Marketing for Law Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Six tips to create a powerful and successful inbound marketing strategy for law firms',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'September 19, 2022',
    id: '72cc0e71-8b01-56cc-af16-3c30e5ae69f6',
    uid: 'blt2f34b0d5dae8d073',
    title: 'Case Study: Handler, Henning and Rosenberg LLC',
    page_url: 'handler-henning-rosenberg',
    key_quote:
      '“Neos has given our firm confidence in moving our case management to the cloud and has increased our firm’s overall efficiency.”  ',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=627&height=418&format=webp&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=1254&height=836&format=webp&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=2508&height=1672&format=webp&quality=50 2508w',
                  sizes: '(min-width: 2508px) 2508px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=2508&height=1672&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=627&height=418&format=jpg&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=1254&height=836&format=jpg&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=2508&height=1672&format=jpg&quality=50 2508w',
                sizes: '(min-width: 2508px) 2508px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2508,
            height: 1672,
          },
        },
        image_alt_text: 'People moving up a chart',
      },
    },
    headline:
      'Long-time Needles Customer Handler, Henning, and Rosenberg LLC Finds Immense Value in Switching to Neos',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text:
      '“Neos has given our firm confidence in moving our case management to the cloud and has increased our firm’s overall efficiency.” ',
    customer: 'Handler, Henning and Rosenberg LLC',
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=85&height=19&format=webp&quality=50 85w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=170&height=38&format=webp&quality=50 170w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=webp&quality=50 340w',
                  sizes: '(min-width: 340px) 340px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=85&height=19&format=png&quality=50 85w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=170&height=38&format=png&quality=50 170w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=png&quality=50 340w',
                sizes: '(min-width: 340px) 340px, 100vw',
              },
            },
            layout: 'constrained',
            width: 340,
            height: 75,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png',
        },
        image_alt_text: 'Karl Truman Law Logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'September 19, 2022',
    id: '8bc423cd-6f45-53fa-bd3e-e5a1c0e0c501',
    uid: 'blt5a580f548f44ebc4',
    title: "What's New: Neos September 2022",
    page_url: 'whats-new/september-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=webp&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=webp&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=webp&quality=50 2559w',
                  sizes: '(min-width: 2559px) 2559px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=640&height=410&format=png&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=1280&height=819&format=png&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3f7e0452be2b8300/6329033ffac7b2660da0125f/MicrosoftTeams-image_(20).png?width=2559&height=1638&format=png&quality=50 2559w',
                sizes: '(min-width: 2559px) 2559px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2559,
            height: 1638,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos September 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the latest product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'September 12, 2022',
    id: '362e215c-6e18-5d0f-99d1-691727cb042a',
    uid: 'blt231db2a294a08311',
    title: '6 Productivity & Efficiency Tips for Family Law Firms',
    page_url: 'efficiency-for-family-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=640&height=419&format=webp&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=1280&height=837&format=webp&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=2560&height=1674&format=webp&quality=50 2560w',
                  sizes: '(min-width: 2560px) 2560px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=2560&height=1674&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=640&height=419&format=jpg&quality=50 640w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=1280&height=837&format=jpg&quality=50 1280w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6661f652baf6d9d5/63209a951ad88e716559d4d7/familylaw2-scaled.jpeg?width=2560&height=1674&format=jpg&quality=50 2560w',
                sizes: '(min-width: 2560px) 2560px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2560,
            height: 1674,
          },
        },
        image_alt_text: 'Productivity tips for family law firms',
      },
    },
    headline: '6 Productivity & Efficiency Tips for Family Law Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Productivity and efficiency tips for family law practices to increase overall output.',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'September 07, 2022',
    id: '5707d596-cf47-5c23-a503-404194b4bd78',
    uid: 'bltde3fff481b793fdb',
    title: 'Five Elements Every Law Firm Website Needs',
    page_url: 'website-for-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte722eb53245a37b0/631debe69f1d04649d6560e8/AdobeStock_506424297.webp',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte722eb53245a37b0/631debe69f1d04649d6560e8/AdobeStock_506424297.webp?width=671&height=390&format=webp&quality=50 671w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte722eb53245a37b0/631debe69f1d04649d6560e8/AdobeStock_506424297.webp?width=1343&height=781&format=webp&quality=50 1343w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte722eb53245a37b0/631debe69f1d04649d6560e8/AdobeStock_506424297.webp?width=2685&height=1561&format=webp&quality=50 2685w',
                  sizes: '(min-width: 2685px) 2685px, 100vw',
                  type: 'image/webp',
                },
              ],
            },
            layout: 'constrained',
            width: 2685,
            height: 1561,
          },
        },
        image_alt_text: 'Websites for law firms',
      },
    },
    headline: 'Five Elements Every Law Firm Website Needs',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'What every law firm website needs to successfully attract potential clients and get them to take the next step. ',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'September 01, 2022',
    id: '5646dbca-4666-58a2-bdff-4101b037c883',
    uid: 'blt542d52b5411065d8',
    title: 'Five Efficiency Tips From a Leading Personal Injury Firm',
    page_url: 'five-efficiency-tips-from-leading-pi-firm',
    key_quote: 'Paul Hernandez shares a metrics-driven approach to success.',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=1250&height=751&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=2499&height=1501&format=webp&quality=50 2499w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=webp&quality=50 4998w',
                  sizes: '(min-width: 4998px) 4998px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=1250&height=751&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=2499&height=1501&format=jpg&quality=50 2499w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=jpg&quality=50 4998w',
                sizes: '(min-width: 4998px) 4998px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4998,
            height: 3001,
          },
        },
        image_alt_text: 'Graphic with blue arrows',
      },
    },
    headline: 'Five Efficiency Tips  From a Leading Personal Injury Firm',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text: 'Paul Hernandez shares a metrics-driven approach to success.',
    customer: 'Kalfus & Nachman',
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=70&height=70&format=webp&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=140&height=140&format=webp&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=webp&quality=50 280w',
                  sizes: '(min-width: 280px) 280px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=70&height=70&format=png&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=140&height=140&format=png&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=png&quality=50 280w',
                sizes: '(min-width: 280px) 280px, 100vw',
              },
            },
            layout: 'constrained',
            width: 280,
            height: 280,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png',
        },
        image_alt_text: 'Kalfus & Nachman Firm Logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'August 31, 2022',
    id: '9902a146-c64b-5d44-b94d-638182c06375',
    uid: 'bltb9c6d8cce5a264e8',
    title: 'TEST',
    page_url: 'test-test-list',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'test test list',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text: null,
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
    ],
    logo_card: {
      logo: {
        image: null,
        image_alt_text: '',
      },
      card_color: {
        colors: null,
      },
    },
  },
  {
    publication_date: 'August 30, 2022',
    id: 'fcff4d97-cb93-5b21-b7ca-0fff9e00d240',
    uid: 'blt2d02e8e2823d2b11',
    title: 'Needles to Neos Q&A: Top 10 Questions, Answered',
    page_url: 'qa-top-10-neos-questions-answered',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=1500&height=1000&format=webp&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=3000&height=2000&format=webp&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=6000&height=4000&format=webp&quality=50 6000w',
                  sizes: '(min-width: 6000px) 6000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=6000&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=1500&height=1000&format=jpg&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=3000&height=2000&format=jpg&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=6000&height=4000&format=jpg&quality=50 6000w',
                sizes: '(min-width: 6000px) 6000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6000,
            height: 4000,
          },
        },
        image_alt_text: 'Woman drawing question mark',
      },
    },
    headline: 'Needles to Neos Q&A: Top 10 Questions, Answered',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Answers to your burning questions about transitioning from on-prem case management to cloud-based, Neos.',
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'August 29, 2022',
    id: '246110f8-595e-5965-8b89-2fab558d564a',
    uid: 'blt40f42d5f92d17c2c',
    title: 'Foundational SEO Strategies for Law Firms',
    page_url: 'SEO-for-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=300&height=200&format=webp&quality=50 300w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=600&height=400&format=webp&quality=50 600w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=1200&height=800&format=webp&quality=50 1200w',
                  sizes: '(min-width: 1200px) 1200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=1200&height=800&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=300&height=200&format=jpg&quality=50 300w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=600&height=400&format=jpg&quality=50 600w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt526354d748449f7d/631deb6bfac7b2660d9fc0fa/ActivityFeedSocial.jpg?width=1200&height=800&format=jpg&quality=50 1200w',
                sizes: '(min-width: 1200px) 1200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 1200,
            height: 800,
          },
        },
        image_alt_text: 'SEO for law firms',
      },
    },
    headline: 'Foundational SEO Strategies for Law Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'How law firms can create an SEO strategy that helps with getting high search engine ranking and more traffic to their website. ',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'August 23, 2022',
    id: 'aab13e99-cd71-562c-a98c-21d602dbaad7',
    uid: 'blt1bf3ade37c51ef56',
    title: 'Neos Named Best of 2022 in The Legal Intelligencer',
    page_url: 'neos-best-of-2022-the-legal-intelligencer',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Neos Named Best of 2022 in The Legal Intelligencer',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Neos was named Best of 2022 in The Legal Intelligencer, a Law.com publication based in New Jersey. ',
    customer: null,
    topics: [
      {
        display_name: 'In the News',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'August 22, 2022',
    id: 'af8b346f-925c-5d63-956b-82bfe44994fb',
    uid: 'blt4d0db6cb3315a438',
    title: "What's New: Neos August 2022",
    page_url: 'whats-new/august-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=2604&height=1667&format=webp&quality=50 2604w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=5209&height=3334&format=webp&quality=50 5209w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=10417&height=6667&format=webp&quality=50 10417w',
                  sizes: '(min-width: 10417px) 10417px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=10417&height=6667&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=2604&height=1667&format=jpg&quality=50 2604w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=5209&height=3334&format=jpg&quality=50 5209w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb0e42d36d9591e56/6303ad0489a31e1a31bb91ff/What_s_New_Graphic_BLACK.jpg?width=10417&height=6667&format=jpg&quality=50 10417w',
                sizes: '(min-width: 10417px) 10417px, 100vw',
              },
            },
            layout: 'constrained',
            width: 10417,
            height: 6667,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos August 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the latest product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'August 17, 2022',
    id: 'a746b445-d340-5f90-ae01-36d388ca5b82',
    uid: 'blt263c3a9e6379bc83',
    title: "Crafting Your Firm's Brand to Attract Ideal Clients",
    page_url: 'branding-for-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=627&height=418&format=webp&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=1254&height=836&format=webp&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=2508&height=1672&format=webp&quality=50 2508w',
                  sizes: '(min-width: 2508px) 2508px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=2508&height=1672&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=627&height=418&format=png&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=1254&height=836&format=png&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte7c806faba6af1bc/630cf5771ee78a063c597302/MicrosoftTeams-image_(13).png?width=2508&height=1672&format=png&quality=50 2508w',
                sizes: '(min-width: 2508px) 2508px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2508,
            height: 1672,
          },
        },
        image_alt_text: 'branding for law firms',
      },
    },
    headline: "Crafting Your Firm's Brand to Attract Ideal Clients",
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'How law firms can craft a brand that resonates with and attracts their ideal clients. ',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'August 10, 2022',
    id: '306a4ad8-908f-547a-a9d6-a0dbe9c41857',
    uid: 'blt77ff63ab2e8c2e9f',
    title: 'Defining Your Target Audience for Law Firms',
    page_url: 'target-market-for-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=1680&height=1120&format=webp&quality=50 1680w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=3360&height=2240&format=webp&quality=50 3360w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=6720&height=4480&format=webp&quality=50 6720w',
                  sizes: '(min-width: 6720px) 6720px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=6720&height=4480&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=1680&height=1120&format=jpg&quality=50 1680w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=3360&height=2240&format=jpg&quality=50 3360w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9c1a3c9c023a8ead/62f522134c4bc9109f699aac/StockSnap_SZCQC1QEW1.jpg?width=6720&height=4480&format=jpg&quality=50 6720w',
                sizes: '(min-width: 6720px) 6720px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6720,
            height: 4480,
          },
        },
        image_alt_text: 'Image of person typing on laptop',
      },
    },
    headline: 'Defining Your Target Audience for Law Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text: 'How law firms can clarify and define their target audience',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'August 10, 2022',
    id: 'bd1bf78f-ea48-50dd-9674-e512c1a2230b',
    uid: 'blt530d32d04c2a3665',
    title:
      'PYMNTS.com - Billing Platform LawPay Integrates With Assembly Software',
    page_url:
      '/pymnts-billing-platform-lawpay-integrates-with-assembly-software/',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: 'Assembly + LawPay: Better Together',
      },
    },
    headline: 'Billing Platform LawPay Integrates With Assembly Software',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      "PYMTs.com covers the launch of Assembly's online payments integration with LawPay, a platform that helps law firms streamline collections and billing.",
    customer: null,
    topics: [
      {
        display_name: 'In the News',
      },
      {
        display_name: 'Integration',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'August 09, 2022',
    id: '5a2ea1e3-fc02-5070-a635-acb3e70d20f8',
    uid: 'blt1d33d4ede361cfc2',
    title: 'Assembly Software Launches Integration with LawPay',
    page_url: 'assembly-software-launches-integration-with-lawpay',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Assembly Software Launches Integration with LawPay',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Neos users will now be able to access the LawPay application and will benefit from a fully integrated payments solution that customizes, automates, and streamlines a firm’s workflow.',
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
      {
        display_name: 'Integration',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'August 01, 2022',
    id: '4c4a1bce-2951-5826-8791-e2b914c3f756',
    uid: 'blt3f3000bf8de3074e',
    title: 'Ultimate Digital Marketing Guide for Law Firms',
    page_url: 'marketing-for-law-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=1426&height=951&format=webp&quality=50 1426w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=2852&height=1901&format=webp&quality=50 2852w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=5703&height=3802&format=webp&quality=50 5703w',
                  sizes: '(min-width: 5703px) 5703px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=5703&height=3802&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=1426&height=951&format=jpg&quality=50 1426w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=2852&height=1901&format=jpg&quality=50 2852w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltffcc1a4c55c8dba9/62ea8e135ab928718a862888/AdobeStock_383161303.jpeg?width=5703&height=3802&format=jpg&quality=50 5703w',
                sizes: '(min-width: 5703px) 5703px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5703,
            height: 3802,
          },
        },
        image_alt_text: 'Image of person typing on laptop',
      },
    },
    headline: 'The Ultimate Digital Marketing Guide for Law Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Ultimate Digital Marketing Guide for Law Firms, including marketing strategy, tactics, and channels. ',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'July 29, 2022',
    id: '7b75bcf5-d149-5fec-b1e7-f87dbc391d24',
    uid: 'blt982368af48424c6b',
    title:
      'Top 10 Things to Consider About Case Management Platform Transitions',
    page_url:
      'top-10-things-to-consider-about-case-management-platform-transitions',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=627&height=419&format=webp&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=1253&height=837&format=webp&quality=50 1253w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=2506&height=1673&format=webp&quality=50 2506w',
                  sizes: '(min-width: 2506px) 2506px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=2506&height=1673&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=627&height=419&format=jpg&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=1253&height=837&format=jpg&quality=50 1253w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta1fbd15ba10b5424/633db347eded0211051c4649/AdobeStock_302471356.jpeg?width=2506&height=1673&format=jpg&quality=50 2506w',
                sizes: '(min-width: 2506px) 2506px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2506,
            height: 1673,
          },
        },
        image_alt_text: '',
      },
    },
    headline:
      'Top 10 things to consider about case management platform transitions',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The transition to a new technology is always mildly anxiety-inducing, to say the least. This is a normal reaction: whether it’s a small switch (like a new phone) or a large one, you’re headed into unknown territory. ',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: null,
        image_alt_text: '',
      },
      card_color: {
        colors: null,
      },
    },
  },
  {
    publication_date: 'July 29, 2022',
    id: 'da3ee676-7cb1-526f-94f9-3ac69d4a9bf3',
    uid: 'bltc9245d7a498e36cb',
    title: 'Major feature releases for Q1/2 2022',
    page_url: 'Q1-2-roundup',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=300&height=200&format=webp&quality=50 300w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=600&height=400&format=webp&quality=50 600w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=1200&height=800&format=webp&quality=50 1200w',
                  sizes: '(min-width: 1200px) 1200px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=1200&height=800&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=300&height=200&format=jpg&quality=50 300w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=600&height=400&format=jpg&quality=50 600w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt09ab177fad72e3fc/631deb3b716e803a3b69730f/ActivityFeedSocial.jpg?width=1200&height=800&format=jpg&quality=50 1200w',
                sizes: '(min-width: 1200px) 1200px, 100vw',
              },
            },
            layout: 'constrained',
            width: 1200,
            height: 800,
          },
        },
        image_alt_text: 'people looking at a whiteboard',
      },
    },
    headline:
      'Top-requested and highly rated Neos features released during Q1-Q2 2022',
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      'Learn about the robust features we rolled out the first half of 2022.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: null,
        image_alt_text: '',
      },
      card_color: {
        colors: null,
      },
    },
  },
  {
    publication_date: 'July 21, 2022',
    id: '06f81c42-6afe-57a7-9946-c89c5255759c',
    uid: 'blt15680d01dab461b6',
    title:
      'For Security-Conscious Law Firms, the Cloud Is a Better Option Than Ever',
    page_url: 'assembly-security-in-the-cloud',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'For Security-Conscious Law Firms, the Cloud Is a Better Option Than Ever',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      "Rick addresses law firm's security concerns and covers six reasons firms can trust the security of today's cloud-based case management solutions.",
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'In the News',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'July 19, 2022',
    id: '781a564d-9181-52b0-addf-da4ad69f5852',
    uid: 'bltd2d660675a7b3ce1',
    title: 'Test Q1Q2 Roundup',
    page_url: 'test-Q1-Q2-roundup',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Test Q1Q2 Roundup page',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text: null,
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: null,
        image_alt_text: '',
      },
      card_color: {
        colors: null,
      },
    },
  },
  {
    publication_date: 'July 15, 2022',
    id: '1cfc36f8-b87e-593a-99ff-ac449d8afd3d',
    uid: 'bltc6342534e1c98b2a',
    title: "What's New: Neos July 2022",
    page_url: 'whats-new/july-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=1443&height=649&format=webp&quality=50 1443w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=2887&height=1299&format=webp&quality=50 2887w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=5773&height=2598&format=webp&quality=50 5773w',
                  sizes: '(min-width: 5773px) 5773px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=5773&height=2598&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=1443&height=649&format=jpg&quality=50 1443w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=2887&height=1299&format=jpg&quality=50 2887w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=5773&height=2598&format=jpg&quality=50 5773w',
                sizes: '(min-width: 5773px) 5773px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5773,
            height: 2598,
          },
        },
        image_alt_text: 'Image of person typing on laptop',
      },
    },
    headline: "What's New: Neos July 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the July product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'June 28, 2022',
    id: 'a487b784-6925-5531-807c-2a8c7a5c612f',
    uid: 'blt7fe7399565865d55',
    title: 'Introducing Assembly Software, Assembly Legal’s New Name and Brand',
    page_url: 'introducing-assembly-software-assembly-legal-new-name-and-brand',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'Introducing Assembly Software, Assembly Legal’s New Name and Brand',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      '"With more than 35 years of industry expertise, Assembly Legal is proud to announce its evolution into Assembly Software."',
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'June 28, 2022',
    id: '2e3c9af5-47e1-5f88-ad49-7b847359a476',
    uid: 'bltbce458c10e57fccb',
    title: 'Non-Eventcast - Practice Management Podcast',
    page_url: 'assembly-non-eventcast-practice-management',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Non-Eventcast - Practice Management',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Jared Corriea interviews Assembly CTO, Jim Garrett, along with several other vendor reps about the features and latest developments in law practice management software. Listen to the podcast on Apple or Spotify.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'In the News',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'June 27, 2022',
    id: 'a247e191-eb20-5a4e-96d8-56c0ee9bdbdf',
    uid: 'blt36ea62664c86ff9d',
    title: "What's New: Neos June 2022",
    page_url: 'whats-new/june-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=1443&height=649&format=webp&quality=50 1443w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=2887&height=1299&format=webp&quality=50 2887w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=5773&height=2598&format=webp&quality=50 5773w',
                  sizes: '(min-width: 5773px) 5773px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=5773&height=2598&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=1443&height=649&format=jpg&quality=50 1443w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=2887&height=1299&format=jpg&quality=50 2887w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta11bd0c9875558ea/62d966d8b08306768355baab/AdobeStock_469644365.jpeg?width=5773&height=2598&format=jpg&quality=50 5773w',
                sizes: '(min-width: 5773px) 5773px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5773,
            height: 2598,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos June 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the June product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'June 02, 2022',
    id: '751a8e30-21b4-533f-9f9b-0b337efdc514',
    uid: 'blta6810004141b7d93',
    title: 'Neos Reimagined Q&A',
    page_url: 'neos-reimagined-qa',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=1625&height=1200&format=webp&quality=50 1625w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=3250&height=2400&format=webp&quality=50 3250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=6500&height=4800&format=webp&quality=50 6500w',
                  sizes: '(min-width: 6500px) 6500px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=6500&height=4800&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=1625&height=1200&format=jpg&quality=50 1625w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=3250&height=2400&format=jpg&quality=50 3250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbbc3c3c0bc9d7b79/62b9b76181636b0f767f89ab/AdobeStock_348397404.jpeg?width=6500&height=4800&format=jpg&quality=50 6500w',
                sizes: '(min-width: 6500px) 6500px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6500,
            height: 4800,
          },
        },
        image_alt_text: 'Black, blue, and purple graphic',
      },
    },
    headline: 'Neos Reimagined Q&A',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The cloud-based case management platform helping legal professional get work done right.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'June 02, 2022',
    id: '3901cff3-86ba-5499-b7e8-c4acbd9154f8',
    uid: 'blt5fa749fa0e706879',
    title: 'Product Comparison Matrix',
    page_url: 'assembly-neos-product-comparison-matrix',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=1932&height=1000&format=webp&quality=50 1932w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=3863&height=2000&format=webp&quality=50 3863w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=7726&height=4000&format=webp&quality=50 7726w',
                  sizes: '(min-width: 7726px) 7726px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=7726&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=1932&height=1000&format=jpg&quality=50 1932w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=3863&height=2000&format=jpg&quality=50 3863w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=7726&height=4000&format=jpg&quality=50 7726w',
                sizes: '(min-width: 7726px) 7726px, 100vw',
              },
            },
            layout: 'constrained',
            width: 7726,
            height: 4000,
          },
        },
        image_alt_text: 'Black abstract technology graphic',
      },
    },
    headline: 'Assembly Neos Product Comparison Matrix',
    lc_content_type: [
      {
        url_segment: 'brochures',
        navigation_name: 'Product Brochures',
        id: 'a1fec80a-abcf-5042-88a1-318775894ce5',
      },
    ],
    teaser_text: 'Compare Assembly Neos features with Needles 5 and 4.',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'June 01, 2022',
    id: '932021d1-fc69-529e-86c3-1b2d98a7cb77',
    uid: 'blt823512924a2b0c7c',
    title: "What's New: Neos May 2022",
    page_url: 'whats-new/may-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos May 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the May product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'April 29, 2022',
    id: 'ae557cf7-ad36-5c4f-b002-a6fdf8783807',
    uid: 'bltcebb3a1ff628a526',
    title:
      'Practically Every Industry Has Accepted The Cloud, So Why Not Legal?',
    page_url:
      'practically-every-industry-has-accepted-the-cloud-so-why-not-legal',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: 'Graphic of cloud technology on black background',
      },
    },
    headline:
      'Practically Every Industry Has Accepted The Cloud, So Why Not Legal?',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Daniel examines why the industry industry has been slow to adopt the cloud, dispels common myths, and provides tactical advice for moving forward in the cloud.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'In the News',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'April 26, 2022',
    id: '7e033c1f-57e7-5c07-bb40-e77ae56f4619',
    uid: 'blta4fe2df4f9e5eafb',
    title: 'Why switch to Neos?',
    page_url: 'why-switch-to-neos',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: 'Image of product Neos dashboard',
      },
    },
    headline: 'Why Switch to Neos?',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The cloud-based case management platform helping legal professional get work done right.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'April 21, 2022',
    id: 'd04e4b40-8c8c-5df7-9346-0888a0846782',
    uid: 'blt8d2ad3dd16e7b893',
    title: "What's New: Neos April 2022",
    page_url: 'whats-new/april-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos April 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the April product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'March 28, 2022',
    id: '117284d4-4ae8-50e7-9fde-167df420c8f7',
    uid: 'blt3395860c155a7882',
    title: "What's New: Neos March 2022",
    page_url: 'whats-new/march-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos March 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the March product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'March 18, 2022',
    id: '6b004362-1683-56be-8c47-6ff9df99a1fc',
    uid: 'bltd4eba4012c8dfa82',
    title: 'Neos Featured in 2022 Practice Management Buyer’s Guide',
    page_url: 'neos-featured-in-2022-practice-management-buyers-guide',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=1560&height=1040&format=webp&quality=50 1560w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=3120&height=2080&format=webp&quality=50 3120w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=6240&height=4160&format=webp&quality=50 6240w',
                  sizes: '(min-width: 6240px) 6240px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=6240&height=4160&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=1560&height=1040&format=jpg&quality=50 1560w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=3120&height=2080&format=jpg&quality=50 3120w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta5074288a73edf8a/62b5e82f889caa0fc2799066/AdobeStock_478677652.jpeg?width=6240&height=4160&format=jpg&quality=50 6240w',
                sizes: '(min-width: 6240px) 6240px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6240,
            height: 4160,
          },
        },
        image_alt_text: 'Hands typing on keyboard 2022',
      },
    },
    headline: "Neos Featured in 2022 Practice Management Buyer's Guide",
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      '"Neos is light years better than any other online platform that I’ve seen — and I did all my due diligence on everything else — and it is technically."',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'March 16, 2022',
    id: 'd4be262d-df44-5c4c-92b6-59d05b315032',
    uid: 'bltebd6871958edc8c6',
    title: '5 Misunderstandings About Client Intake Software',
    page_url: '5-common-misunderstandings-about-client-intake-software',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: '5 Misunderstandings About Client Intake Software',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'McKay dives into five common misunderstandings about intake software he has seen with smaller law firms and provides questions to ask when test-driving intake products.',
    customer: null,
    topics: [
      {
        display_name: 'In the News',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'March 05, 2022',
    id: '9356cc17-7d2d-550e-a7b3-b9be996dced0',
    uid: 'blt18f9c1886220cb2f',
    title: 'Assembly - Fast Track Legal',
    page_url: 'assembly-fast-track-legal',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Assembly - Fast Track Legal',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Local lifestyle magazine covers the long history of Trialworks and the decades of industry experience the company brings to Assembly.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Trends',
      },
      {
        display_name: 'In the News',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'February 26, 2022',
    id: 'bf86f2de-e5dd-5c85-8631-01dc5c82ffb9',
    uid: 'blt1212501125296795',
    title:
      "Your Cloud Questions Answered by Assembly's Chief Technology Officer",
    page_url: 'your-cloud-questions-answered',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=1500&height=1000&format=webp&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=3000&height=2000&format=webp&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=6000&height=4000&format=webp&quality=50 6000w',
                  sizes: '(min-width: 6000px) 6000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=6000&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=1500&height=1000&format=jpg&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=3000&height=2000&format=jpg&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blte9d63622441defef/62b8eb32e218a20f88dda262/AdobeStock_170949148.jpeg?width=6000&height=4000&format=jpg&quality=50 6000w',
                sizes: '(min-width: 6000px) 6000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6000,
            height: 4000,
          },
        },
        image_alt_text: 'Person drawing lighbulb',
      },
    },
    headline:
      'Your Cloud Questions Answered by Assembly’s Chief Technology Officer',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      '"Law firms who are still on-premise with their case management software have asked us a lot of questions about the cloud. We gathered the most commonly"',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'February 22, 2022',
    id: '54a39e51-f5df-529a-af94-35aa509b8372',
    uid: 'blt438ffeea5a4fd5c3',
    title: "What's New: Neos February 2022",
    page_url: 'whats-new/february-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos February 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the February product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'February 16, 2022',
    id: '9c6ab0df-29c4-5560-94ee-f51854c84c2d',
    uid: 'blt51899fc8e2b18577',
    title: 'Case Study: Darby Law Group',
    page_url: 'darby-law-group-case-study',
    key_quote:
      '“Neos has been very good luck for me. Since I started using it at my new firm, I’ve had the best three months of my legal career in terms of settling cases.”',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=1250&height=700&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=2500&height=1400&format=webp&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=5000&height=2800&format=webp&quality=50 5000w',
                  sizes: '(min-width: 5000px) 5000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=5000&height=2800&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=1250&height=700&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=2500&height=1400&format=jpg&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbde7cdf04f9d4ffb/62b5e8a9ff0f000f7b2643d3/AdobeStock_108039455.jpeg?width=5000&height=2800&format=jpg&quality=50 5000w',
                sizes: '(min-width: 5000px) 5000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5000,
            height: 2800,
          },
        },
        image_alt_text: 'Image of man behind graphic of charts and graphs',
      },
    },
    headline:
      'Neos Helps Darby Law Group Settle Workers’ Comp Cases with Ease and Efficiency',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text:
      '“Neos has been very good luck for me. Since I started using it at my new firm, I’ve had the best three months of my legal career in terms of settling cases.”',
    customer: 'Matt Darby',
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=70&height=70&format=webp&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=140&height=140&format=webp&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=280&height=280&format=webp&quality=50 280w',
                  sizes: '(min-width: 280px) 280px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=280&height=280&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=70&height=70&format=png&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=140&height=140&format=png&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png?width=280&height=280&format=png&quality=50 280w',
                sizes: '(min-width: 280px) 280px, 100vw',
              },
            },
            layout: 'constrained',
            width: 280,
            height: 280,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltb96ff1c34e949ee5/62b4ed8b61e0990f94923ef1/darby_280_280.png',
        },
        image_alt_text: 'Darby Law Logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'February 09, 2022',
    id: '78cb128d-b617-56c3-bbbe-24d71c2260fe',
    uid: 'bltbaebfbe7b5a74dad',
    title: 'New Neos Featured Designed to Help Your Firm Make More Money',
    page_url: 'new-neos-features-designed-to-help-your-firm-make-more-money',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'New Neos Feature Designed to Help Your Firm Make More Money',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Neos cloud-based case management is currently helping over 400 firms make more money. We help law firms be more profitable through dramatically reduced IT',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'February 09, 2022',
    id: 'a3d24380-d172-5a4c-8237-a45b31958f80',
    uid: 'blt03a4de2af57b3c1e',
    title: 'Multi-Cloud: The Solution You Never Knew You Needed',
    page_url: 'multi-cloud-the-solution-you-never-knew-you-needed',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=1125&height=750&format=webp&quality=50 1125w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=2250&height=1500&format=webp&quality=50 2250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=4500&height=3000&format=webp&quality=50 4500w',
                  sizes: '(min-width: 4500px) 4500px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=4500&height=3000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=1125&height=750&format=jpg&quality=50 1125w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=2250&height=1500&format=jpg&quality=50 2250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt3262583c4b4be7ac/62ba20234a5c4d0fcb1893b2/AdobeStock_412177563.jpeg?width=4500&height=3000&format=jpg&quality=50 4500w',
                sizes: '(min-width: 4500px) 4500px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4500,
            height: 3000,
          },
        },
        image_alt_text: 'Hands typing on keyboard with cloud graphic',
      },
    },
    headline: 'Multi-Cloud: The Solution You Never Knew You Needed',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Multi-cloud has been getting some buzz lately, but you may not know exactly what it is, how it works, or why it matters to you and your law firm. You may',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'February 08, 2022',
    id: '8ba984a0-4f34-540c-99ac-8ff82f744908',
    uid: 'bltdcd4edd4d311b470',
    title: 'Law Technology Today: Debunking Legal Tech Myths',
    page_url: 'debunking-legal-tech-myths',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Law Technology Today: Debunking Legal Tech Myths',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Jim debunks some common myths about legal tech—from robots replacing lawyers to addressing legal tech integrations—and provides practical tips firms can use today.',
    customer: null,
    topics: [
      {
        display_name: 'In the News',
      },
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Integration',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assemby mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'February 01, 2022',
    id: '1e1c6068-5f2b-50ab-adbd-8b077f2e3e40',
    uid: 'bltda6f5fcc5462777a',
    title: 'Leading During Turbulent Times',
    page_url: 'leading-during-turbulent-times',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Leading in Turbulent Times',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Daniel discusses five things you need to be a highly effective leader during uncertain or challenging times.',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Hybrid & Remote Work',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'January 27, 2022',
    id: '943befc2-7791-58c9-8575-bf18d8b9d421',
    uid: 'blt510de04fe5afe55d',
    title: "What's New: Neos January 2022",
    page_url: 'whats-new/january-2022',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos January 2022",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the January product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'January 25, 2022',
    id: 'f8a9aedc-07c1-5578-94a1-afb27b765254',
    uid: 'bltb03455812dc97426',
    title: 'Make 2022 the Year of (Great) Law Firm Reviews',
    page_url: 'make-2022-the-year-of-great-law-firm-reviews',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=1082&height=721&format=webp&quality=50 1082w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=2165&height=1443&format=webp&quality=50 2165w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=4329&height=2886&format=webp&quality=50 4329w',
                  sizes: '(min-width: 4329px) 4329px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=4329&height=2886&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=1082&height=721&format=jpg&quality=50 1082w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=2165&height=1443&format=jpg&quality=50 2165w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blta6b60a80a4d2bfe4/62b5e82fd466000f8c78a072/AdobeStock_392432837.jpeg?width=4329&height=2886&format=jpg&quality=50 4329w',
                sizes: '(min-width: 4329px) 4329px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4329,
            height: 2886,
          },
        },
        image_alt_text: 'Hands typing',
      },
    },
    headline: 'Make 2022 the Year of (Great) Law Firm Reviews',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The importance of word-of-mouth marketing isn’t new. Long before business websites and platforms like Yelp and Google reviews, word of mouth was among the',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'January 10, 2022',
    id: '05c397d0-fb57-5093-aa75-e4e40c4289cc',
    uid: 'blt317b849f2dfbb176',
    title: 'Q&A: Top 20 Neos Intake Pro Questions',
    page_url: 'qa-top-20-neos-intake-pro-questions',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=1500&height=1000&format=webp&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=3000&height=2000&format=webp&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=6000&height=4000&format=webp&quality=50 6000w',
                  sizes: '(min-width: 6000px) 6000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=6000&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=1500&height=1000&format=jpg&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=3000&height=2000&format=jpg&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt047bfba5d14e357c/62ba0bca64a9600f86bb7fc3/AdobeStock_206674448.jpeg?width=6000&height=4000&format=jpg&quality=50 6000w',
                sizes: '(min-width: 6000px) 6000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6000,
            height: 4000,
          },
        },
        image_alt_text: 'Woman drawing question mark',
      },
    },
    headline: 'Q&A: Top 20 Neos Intake Pro Questions',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Neos Intake Pro gives law firms actionable insights at every stage of the customer journey, helping you capture leads effectively and turn more of them',
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
      {
        display_name: 'Integration',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'December 15, 2021',
    id: 'b68c39ed-43c8-506b-b165-b5cadb519bb4',
    uid: 'bltcb5505cd6bc9f7a9',
    title: "What's New: Neos December 2021",
    page_url: 'whats-new/december-2021',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos December 2021",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the December product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'November 17, 2021',
    id: '041e681d-9d00-5a11-a088-e7eeef636b4f',
    uid: 'blt8b91e62664f091e7',
    title: "What's New: Neos November 2021",
    page_url: 'whats-new-november-2021',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: "What's New: Neos November 2021",
    lc_content_type: [
      {
        url_segment: 'product-releases',
        navigation_name: 'Product Releases',
        id: 'a260e5be-80c5-50b5-84b7-49e1d69a7504',
      },
    ],
    teaser_text:
      "What's new in Neos? Check out the November product release for cloud-based legal case management software Neos.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'November 17, 2021',
    id: '9844ffd6-7f43-5f00-872c-4a323f973403',
    uid: 'bltd52b6ecf42713600',
    title: 'Assembly Software Announces Intake Pro',
    page_url: 'assembly-software-announces-launch-of-neos-intake-pro',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Assembly Software Announces Intake Pro',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Assembly announces the launch of Neos Intake Pro, a software module that gives law firms actionable insights at each stage of the client journey and, when combined with Neos case management software, offers seamless conversion of intakes to cases.',
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'November 15, 2021',
    id: '3fa94e30-bef3-501e-bbaf-847380a609cb',
    uid: 'blt5342c77e05c34972',
    title:
      'Neos Intake Pro is Here to Give Law Firms End-to-End Client Management',
    page_url: 'neos-intake-pro-is-here',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'Neos Intake Pro is Here to Give Law Firms End-to-End Client Management',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'We are excited to announce the launch of Neos Intake Pro, a software module that gives law firms actionable insights at each stage of the client journey',
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
      {
        display_name: 'Integration',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'October 14, 2021',
    id: '0b56b9e8-b7a7-5951-8822-8ea7cc86e520',
    uid: 'blt4f424e190c56aa7b',
    title:
      'Investing in the Future of Cloud-Based Legal Technology: A Q&A With Our Product Leaders',
    page_url:
      'investing-in-the-future-of-cloud-based-legal-technology-a-qa-with-our-product-leaders',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=1250&height=653&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=2500&height=1307&format=webp&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=5000&height=2613&format=webp&quality=50 5000w',
                  sizes: '(min-width: 5000px) 5000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=5000&height=2613&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=1250&height=653&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=2500&height=1307&format=jpg&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltdc05d97464b1a86e/62e029bf5c954177895ab365/SecuritySocial.jpg?width=5000&height=2613&format=jpg&quality=50 5000w',
                sizes: '(min-width: 5000px) 5000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5000,
            height: 2613,
          },
        },
        image_alt_text: 'Blue abstract graphic image',
      },
    },
    headline:
      'Investing in the Future of Cloud-Based Legal Technology: A Q&A With Our Product Leaders',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The concept of cloud-based technology is not new, but how does this impact legal case management and the way that law firms operate? We asked Assembly',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'October 14, 2021',
    id: '8fcb452e-578b-5e5c-8017-b68ed77c5ea0',
    uid: 'blta4443d62e87dd7f6',
    title: '4 Reasons Your Law Firm Should Consider Going Permanently Hybrid',
    page_url: '4-reasons-your-law-firm-should-go-hybrid',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=1500&height=844&format=webp&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=3000&height=1688&format=webp&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=6000&height=3376&format=webp&quality=50 6000w',
                  sizes: '(min-width: 6000px) 6000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=6000&height=3376&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=1500&height=844&format=jpg&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=3000&height=1688&format=jpg&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32fb3d108ae36b2a/62b5e83216d5870fbb86faac/AdobeStock_472697049.jpeg?width=6000&height=3376&format=jpg&quality=50 6000w',
                sizes: '(min-width: 6000px) 6000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6000,
            height: 3376,
          },
        },
        image_alt_text: 'Hybrid work sign',
      },
    },
    headline:
      '4 Reasons Your Law Firm Should Consider Going Permanently Hybrid',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'In the spring of 2020, many law firms — like other businesses — were forced to adopt new systems. In those early days, many of us expected the disruption',
    customer: null,
    topics: [
      {
        display_name: 'Hybrid & Remote Work',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'September 10, 2021',
    id: '647226ab-a6ea-5930-9b2b-f10991dd5fb4',
    uid: 'bltbc2569051c4bdf16',
    title: 'On the Road Again: 3 Driving Trends That Can Impact Road Safety',
    page_url: 'on-the-road-again-3-driving-trends-that-can-impact-road-safety',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=964&height=557&format=webp&quality=50 964w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=1929&height=1114&format=webp&quality=50 1929w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=3857&height=2228&format=webp&quality=50 3857w',
                  sizes: '(min-width: 3857px) 3857px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=3857&height=2228&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=964&height=557&format=jpg&quality=50 964w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=1929&height=1114&format=jpg&quality=50 1929w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt417cf5f9d9ee47a0/62b5e8314de82610183d79bd/AdobeStock_312737093.jpeg?width=3857&height=2228&format=jpg&quality=50 3857w',
                sizes: '(min-width: 3857px) 3857px, 100vw',
              },
            },
            layout: 'constrained',
            width: 3857,
            height: 2228,
          },
        },
        image_alt_text: 'Road with cards',
      },
    },
    headline: 'On the Road Again: 3 Driving Trends That Can Impact Road Safety',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The scene on the roads keeps evolving and personal injury attorneys should take note of the trends. As uncertainty remains a constant late into 2021',
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'August 11, 2021',
    id: '88fddc4e-cd7c-5fd0-a89b-846cf53ad719',
    uid: 'blt389e0a886d18e99a',
    title:
      '4 Efficiency Tips We Learned from a Personal Injury Attorney with Kalfus & Nachman',
    page_url: '4-efficiency-tips-we-learned-from-pi-firm-kalfus-nachman',
    key_quote: 'Paul Hernandez shares a metrics-driven approach to success.',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=1250&height=751&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=2499&height=1501&format=webp&quality=50 2499w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=webp&quality=50 4998w',
                  sizes: '(min-width: 4998px) 4998px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=1250&height=751&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=2499&height=1501&format=jpg&quality=50 2499w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=jpg&quality=50 4998w',
                sizes: '(min-width: 4998px) 4998px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4998,
            height: 3001,
          },
        },
        image_alt_text: 'Graphic with blue arrows',
      },
    },
    headline:
      '4 Efficiency Tips We Learned from a Personal Injury Attorney with Kalfus & Nachman',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text: 'Paul Hernandez shares a metrics-driven approach to success.',
    customer: 'Kalfus & Nachman',
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=70&height=70&format=webp&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=140&height=140&format=webp&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=webp&quality=50 280w',
                  sizes: '(min-width: 280px) 280px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=70&height=70&format=png&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=140&height=140&format=png&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=png&quality=50 280w',
                sizes: '(min-width: 280px) 280px, 100vw',
              },
            },
            layout: 'constrained',
            width: 280,
            height: 280,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png',
        },
        image_alt_text: 'Kalfus & Nachman Firm Logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'July 08, 2021',
    id: 'c1ce90b4-3596-5c04-b13c-f3e9d6e08d86',
    uid: 'bltea07bef36d7cfb42',
    title: 'Cohen Kolodny Abuse Analytics Case Study',
    page_url: 'cohen-kolodny-abuse-analyticscase-study',
    key_quote:
      '“Neos is the most robust and user-friendly software on the market. I don’t know how a firm could live without it.”',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=1404&height=936&format=webp&quality=50 1404w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=2808&height=1872&format=webp&quality=50 2808w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=5616&height=3744&format=webp&quality=50 5616w',
                  sizes: '(min-width: 5616px) 5616px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=5616&height=3744&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=1404&height=936&format=jpg&quality=50 1404w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=2808&height=1872&format=jpg&quality=50 2808w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltab145709f6b87ab9/62b9eee0d0f5850fe791fa33/AdobeStock_81346463.jpeg?width=5616&height=3744&format=jpg&quality=50 5616w',
                sizes: '(min-width: 5616px) 5616px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5616,
            height: 3744,
          },
        },
        image_alt_text: 'hand drawing graphic',
      },
    },
    headline:
      'Cohen Kolodny Abuse Analytics Sees 5X Improvement in Managing Cases with Neos',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text:
      'When comparing Neos to the competitive cloud solution, Cohen finds it easier to use.',
    customer: 'David Cohen',
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=70&height=70&format=webp&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=140&height=140&format=webp&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=280&height=280&format=webp&quality=50 280w',
                  sizes: '(min-width: 280px) 280px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=280&height=280&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=70&height=70&format=png&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=140&height=140&format=png&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png?width=280&height=280&format=png&quality=50 280w',
                sizes: '(min-width: 280px) 280px, 100vw',
              },
            },
            layout: 'constrained',
            width: 280,
            height: 280,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt6273329a4ffcdfe3/62b5a24f29e2dd0ff591b1d7/cohenk.png',
        },
        image_alt_text: 'Cohen Kolodny Firm Logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'June 14, 2021',
    id: '77b6da70-5f7a-55c4-85f0-65f0eca06d2c',
    uid: 'blt75aa55ca00984815',
    title: "Don't take a Vacation from Law Firm Efficiency",
    page_url: 'dont-take-a-vacation-from-law-firm-efficiency',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=998&height=561&format=webp&quality=50 998w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=1996&height=1121&format=webp&quality=50 1996w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=3992&height=2242&format=webp&quality=50 3992w',
                  sizes: '(min-width: 3992px) 3992px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=3992&height=2242&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=998&height=561&format=jpg&quality=50 998w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=1996&height=1121&format=jpg&quality=50 1996w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltc7094e6d0dfa641e/62b8eabd4086f70f9e5b3736/AdobeStock_173175387.jpeg?width=3992&height=2242&format=jpg&quality=50 3992w',
                sizes: '(min-width: 3992px) 3992px, 100vw',
              },
            },
            layout: 'constrained',
            width: 3992,
            height: 2242,
          },
        },
        image_alt_text: 'Man working at a pool',
      },
    },
    headline: "Don't take a Vacation from Law Firm Efficiency",
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Summer means higher temperatures, more outdoor activities, and the sun setting later. It also means staff vacations, kids home from school, and other',
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'June 07, 2021',
    id: 'c346e352-47ef-5d3d-b9da-5e27cbb78e1e',
    uid: 'bltb5a0a27782f3135b',
    title: "How to Improve Your Firm's Client Communication and Reputation",
    page_url: 'how-to-improve-your-firms-client-communication-and-reputation',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=1680&height=1120&format=webp&quality=50 1680w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=3360&height=2240&format=webp&quality=50 3360w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=6720&height=4480&format=webp&quality=50 6720w',
                  sizes: '(min-width: 6720px) 6720px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=6720&height=4480&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=1680&height=1120&format=jpg&quality=50 1680w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=3360&height=2240&format=jpg&quality=50 3360w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0b5c3724b73f3671/62ba103af146ac100d9cc2d2/AdobeStock_495297892.jpeg?width=6720&height=4480&format=jpg&quality=50 6720w',
                sizes: '(min-width: 6720px) 6720px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6720,
            height: 4480,
          },
        },
        image_alt_text: '',
      },
    },
    headline: "How to Improve Your Firm's Client Communication and Reputation",
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Developing and maintaining a relationship of trust with your clients has never been so important for law firms. As many as 93% of consumers say online',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'June 01, 2021',
    id: '8a9919fb-2538-556d-8687-649ba4ffcaa2',
    uid: 'bltc7c99972ffaf0c6e',
    title:
      'The latest Neos features roundup: 6 key areas to drive firm performance',
    page_url:
      'the-latest-neos-features-roundup-6-key-areas-to-drive-firm-performance',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'The latest Neos features roundup: 6 key areas to drive firm performance',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'The past year has brought new challenges and created many opportunities for law firms. We are inspired by the resilience of our customers and have Over the past several months, we’ve released dozens of powerful new features and enhancements designed to fuel greater success for our users. We’re sharing 6 of the key areas we’ve focused on — core case management capabilities, enhanced reporting, improved staff collaboration, efficient document management, faster financial management, and enhanced security.',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'May 11, 2021',
    id: '99eb5df1-3ebc-5577-96c6-c4653970e276',
    uid: 'bltb4a1499bbd16548c',
    title: 'Karl Truman Case Study',
    page_url: 'karl-truman-case-study',
    key_quote:
      '“A lot of companies claim to have the latest and greatest cloud product … having gone down that path and come back, I am a firm believer in Neos and have already recommended it to many law firms.”',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=627&height=418&format=webp&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=1254&height=836&format=webp&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=2508&height=1672&format=webp&quality=50 2508w',
                  sizes: '(min-width: 2508px) 2508px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=2508&height=1672&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=627&height=418&format=jpg&quality=50 627w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=1254&height=836&format=jpg&quality=50 1254w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd0512863f3fe4eb2/632db9f6eb66820cfba0fe9e/AdobeStock_467982820.jpeg?width=2508&height=1672&format=jpg&quality=50 2508w',
                sizes: '(min-width: 2508px) 2508px, 100vw',
              },
            },
            layout: 'constrained',
            width: 2508,
            height: 1672,
          },
        },
        image_alt_text: 'People moving up a chart',
      },
    },
    headline:
      'Karl Truman Law Office finds productivity and innovation with Neos',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text:
      'Karl Truman Law Office finds productivity and innovation with Neos.',
    customer: 'Karl Truman',
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=85&height=19&format=webp&quality=50 85w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=170&height=38&format=webp&quality=50 170w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=webp&quality=50 340w',
                  sizes: '(min-width: 340px) 340px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=85&height=19&format=png&quality=50 85w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=170&height=38&format=png&quality=50 170w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png?width=340&height=75&format=png&quality=50 340w',
                sizes: '(min-width: 340px) 340px, 100vw',
              },
            },
            layout: 'constrained',
            width: 340,
            height: 75,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt0949e38d42c961d7/636d5feda374f37d13a04e27/logo.png',
        },
        image_alt_text: 'Karl Truman Law Logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'March 24, 2021',
    id: '3134f456-bd58-5216-8f5c-1508d617b45d',
    uid: 'blt699b461e30b9b16a',
    title:
      'Increase Visibility and Control Over Your Cases with the New Activity Feed',
    page_url:
      'increase-visibility-and-control-over-your-cases-with-the-new-activity-feed',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'Increase Visibility and Control Over Your Cases with the New Activity Feed',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      "How many times have you been pulled into someone else’s case without time to get up to speed? Your Neos' Activity Feed is a chronological history of everything that has happened with the case. It is the perfect place to get a clear summary of where a case stands.",
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'January 27, 2021',
    id: 'c81df422-9a4f-5414-aa3c-4bbcd40edd82',
    uid: 'blta3bb45989f047061',
    title: 'Riskier Driving and the Impact for Personal Injury Practices',
    page_url: 'riskier-driving-and-the-impact-for-personal-injury-practices',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=1368&height=912&format=webp&quality=50 1368w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=2736&height=1824&format=webp&quality=50 2736w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=5472&height=3648&format=webp&quality=50 5472w',
                  sizes: '(min-width: 5472px) 5472px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=5472&height=3648&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=1368&height=912&format=jpg&quality=50 1368w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=2736&height=1824&format=jpg&quality=50 2736w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt44e5dabea5f52a8f/62ba57baad26e90f819fbe36/AdobeStock_212679311.jpeg?width=5472&height=3648&format=jpg&quality=50 5472w',
                sizes: '(min-width: 5472px) 5472px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5472,
            height: 3648,
          },
        },
        image_alt_text: 'Open road',
      },
    },
    headline: 'Riskier Driving and the Impact for Personal Injury Practices',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Changes in traffic as a result of the pandemic continue to be an important trend for personal injury firms to monitor. Studies from INRIX and the National',
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'December 24, 2020',
    id: '6f448f77-fbc8-5d56-bf54-d636b29a03a7',
    uid: 'blt9d7490532934fedc',
    title: 'How Schad & Schad greatly increased efficiency with Neos',
    page_url: 'case-study-schad-schad',
    key_quote:
      '“ Neos is light years better than any other online platform that I’ve seen — and I did all my due diligence on everything else — and it is technically superior.”',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=1240&height=592&format=webp&quality=50 1240w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=2480&height=1184&format=webp&quality=50 2480w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=4960&height=2367&format=webp&quality=50 4960w',
                  sizes: '(min-width: 4960px) 4960px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=4960&height=2367&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=1240&height=592&format=jpg&quality=50 1240w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=2480&height=1184&format=jpg&quality=50 2480w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltd4b7be9d815ef0f6/62b9eeabd0f5850fe791fa2f/AdobeStock_478098898.jpeg?width=4960&height=2367&format=jpg&quality=50 4960w',
                sizes: '(min-width: 4960px) 4960px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4960,
            height: 2367,
          },
        },
        image_alt_text: 'Efficiency building blocks',
      },
    },
    headline: 'How one firm greatly increased efficiency with Neos',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text: null,
    customer: 'Matt Schad',
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=70&height=70&format=webp&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=140&height=140&format=webp&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=280&height=280&format=webp&quality=50 280w',
                  sizes: '(min-width: 280px) 280px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=280&height=280&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=70&height=70&format=png&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=140&height=140&format=png&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png?width=280&height=280&format=png&quality=50 280w',
                sizes: '(min-width: 280px) 280px, 100vw',
              },
            },
            layout: 'constrained',
            width: 280,
            height: 280,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blteb2d3ef9abfa429d/62b63da9c6bccd0fa5f3ae5c/schadwhite.png',
        },
        image_alt_text: 'Schad Law Logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'October 01, 2020',
    id: '1226b425-462b-51fb-ad16-9f6c599e6548',
    uid: 'blt005026f9b73b49f2',
    title: 'Making the switch to Neos - Data Conversion Sheet (old branding)',
    page_url: 'neos-data-conversion',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Making the switch to Neos',
    lc_content_type: [
      {
        url_segment: 'brochures',
        navigation_name: 'Product Brochures',
        id: 'a1fec80a-abcf-5042-88a1-318775894ce5',
      },
    ],
    teaser_text:
      'Don’t let the data conversion process discourage your firm from making the business decision to partner with the leading legal case management technology provider, Neos.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'October 01, 2020',
    id: 'd78b23f3-f618-527d-b996-1391c0e74ab7',
    uid: 'blt93180544e7f71178',
    title: 'Integration Overview Sheet',
    page_url: 'neos-integration-overview',
    key_quote:
      'Work with clients, partners, providers, and staff\u001fwithin one system so that you can run your law practice more efficiently and improve service.',
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Integration Overview',
    lc_content_type: [
      {
        url_segment: 'brochures',
        navigation_name: 'Product Brochures',
        id: 'a1fec80a-abcf-5042-88a1-318775894ce5',
      },
    ],
    teaser_text:
      'Neos offers a comprehensive network of integration options through our open architecture and direct partnerships. Work with clients, partners, providers, and staff\u001fwithin one system so that you can run your law practice more efficiently and improve service.',
    customer: 'Assembly Neos',
    topics: [
      {
        display_name: 'Integration',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Assembly Neos Logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'October 01, 2020',
    id: 'beae41ef-7c35-5394-9375-c27083e044c3',
    uid: 'blt16da278e2223ac9f',
    title: 'Migrating to Neos for existing Neos users',
    page_url: 'migrating-to-neos-for-existing-needles-users',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Migrating to Neos for existing Needles users',
    lc_content_type: [
      {
        url_segment: 'brochures',
        navigation_name: 'Product Brochures',
        id: 'a1fec80a-abcf-5042-88a1-318775894ce5',
      },
    ],
    teaser_text:
      'Transitioning to a new case management platform can be daunting, but it doesn’t have to be. We built Neos to ease the data migration process for our valued customers.',
    customer: null,
    topics: [
      {
        display_name: 'Cloud',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt61bd1b38db9ae115/62b5856b4de82610183d75ff/neossq.png',
        },
        image_alt_text: 'Neos logo',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'September 18, 2020',
    id: '62fe7dab-3dd7-59c6-88c0-eb7780880553',
    uid: 'blt2eadc18d517ad41f',
    title: 'Moving to Cloud-Based Case Management: 5 Things to Know',
    page_url: 'moving-to-cloud-based-case-management-5-things-to-know',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=1932&height=1000&format=webp&quality=50 1932w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=3863&height=2000&format=webp&quality=50 3863w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=7726&height=4000&format=webp&quality=50 7726w',
                  sizes: '(min-width: 7726px) 7726px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=7726&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=1932&height=1000&format=jpg&quality=50 1932w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=3863&height=2000&format=jpg&quality=50 3863w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt562e158926273b78/62b5e9274d83761026d9c417/AdobeStock_288623237.jpeg?width=7726&height=4000&format=jpg&quality=50 7726w',
                sizes: '(min-width: 7726px) 7726px, 100vw',
              },
            },
            layout: 'constrained',
            width: 7726,
            height: 4000,
          },
        },
        image_alt_text: 'Black and purple abstract graphic',
      },
    },
    headline: 'Moving to Cloud-Based Case Management: 5 Things to Know',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Are you using on-premise case management today and considering the cloud? Whether you’re an existing Needles user or coming from another platform,',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
      {
        display_name: 'Cloud',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'September 14, 2020',
    id: 'ed2e9cac-a64b-5309-9cf6-9cfcdcc22ff3',
    uid: 'blteb20756b2c469f9b',
    title: 'Collaboration in the New Workday: Success Factors for Firms',
    page_url: 'collaboration-in-the-new-workday-success-factors-for-firms',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=2376&height=1584&format=webp&quality=50 2376w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=4752&height=3168&format=webp&quality=50 4752w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=9504&height=6336&format=webp&quality=50 9504w',
                  sizes: '(min-width: 9504px) 9504px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=9504&height=6336&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=2376&height=1584&format=jpg&quality=50 2376w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=4752&height=3168&format=jpg&quality=50 4752w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt796222489555f83c/62b8eadb889caa0fc279920d/AdobeStock_407990427.jpeg?width=9504&height=6336&format=jpg&quality=50 9504w',
                sizes: '(min-width: 9504px) 9504px, 100vw',
              },
            },
            layout: 'constrained',
            width: 9504,
            height: 6336,
          },
        },
        image_alt_text: 'Woman at a laptop',
      },
    },
    headline: 'Collaboration in the New Workday: Success Factors for Firms',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Team collaboration has a new face in today’s work environment, where remote work poses both challenges too and opportunities for a firm’s success. ',
    customer: null,
    topics: [
      {
        display_name: 'Hybrid & Remote Work',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'June 15, 2020',
    id: 'c6e24c07-ae43-5410-984e-d4dbd86f6b54',
    uid: 'blt0d9cd75fd2705872',
    title:
      'How to Market Your Law Firm in a Changing and Unpredictable Environment',
    page_url:
      'how-to-market-your-law-firm-in-a-changing-and-unpredictable-environment',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=1296&height=864&format=webp&quality=50 1296w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=2592&height=1728&format=webp&quality=50 2592w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=5184&height=3456&format=webp&quality=50 5184w',
                  sizes: '(min-width: 5184px) 5184px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=5184&height=3456&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=1296&height=864&format=jpg&quality=50 1296w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=2592&height=1728&format=jpg&quality=50 2592w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt13a3964376b00855/62b8f8b480cd710f93e142af/AdobeStock_195360007.jpeg?width=5184&height=3456&format=jpg&quality=50 5184w',
                sizes: '(min-width: 5184px) 5184px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5184,
            height: 3456,
          },
        },
        image_alt_text: 'Tech marketing graphic',
      },
    },
    headline:
      'How to Market Your Law Firm in a Changing and Unpredictable Environment',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'No business could have predicted what 2020 was going to look like when determining marketing and advertising budgets for the year. If anything, it looked',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'June 01, 2020',
    id: '7db7eb3f-28a4-5edf-b7f2-5bdede9d56df',
    uid: 'blt9a0fb947f1e554a2',
    title: 'Building Resilience During Uncertain Times',
    page_url:
      'building-resilience-during-uncertain-times-prominent-virginia-firm-kalfus-nachman-looks-to-the-future',
    key_quote:
      'Kalfus & Nachman shifted approximately 80% of the firm to a remote work environment and is using this slower time wisely, shifting business priorities, practicing the importance of communication, and preparing for a post-pandemic world.',
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=1504&height=1000&format=webp&quality=50 1504w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=3008&height=2000&format=webp&quality=50 3008w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=6016&height=4000&format=webp&quality=50 6016w',
                  sizes: '(min-width: 6016px) 6016px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=6016&height=4000&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=1504&height=1000&format=jpg&quality=50 1504w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=3008&height=2000&format=jpg&quality=50 3008w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt243eb291a7411fd1/62b9ee637f58f9101fcead37/AdobeStock_419173114.jpeg?width=6016&height=4000&format=jpg&quality=50 6016w',
                sizes: '(min-width: 6016px) 6016px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6016,
            height: 4000,
          },
        },
        image_alt_text: 'Image of golden egg',
      },
    },
    headline:
      'Building Resilience During Uncertain Times: Prominent Virginia Firm Kalfus & Nachman Looks to the Future',
    lc_content_type: [
      {
        url_segment: 'customer-stories',
        navigation_name: 'Customer Stories',
        id: 'ff203948-5d5e-52cf-ac67-6b609766efe5',
      },
    ],
    teaser_text: 'Building Resilience During Uncertain Times',
    customer: 'Kalfus & Nachman, PC',
    topics: [
      {
        display_name: 'Hybrid & Remote Work',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=70&height=70&format=webp&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=140&height=140&format=webp&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=webp&quality=50 280w',
                  sizes: '(min-width: 280px) 280px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=70&height=70&format=png&quality=50 70w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=140&height=140&format=png&quality=50 140w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png?width=280&height=280&format=png&quality=50 280w',
                sizes: '(min-width: 280px) 280px, 100vw',
              },
            },
            layout: 'constrained',
            width: 280,
            height: 280,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt5843ce1aeefef99e/62b637317f58f9101fcea868/KalfusNachman-BW.png',
        },
        image_alt_text: 'Kalfus & Nachman Firm Logo',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'May 27, 2020',
    id: 'c88a68fe-a262-5e39-8c75-ed8647733d56',
    uid: 'bltdc60d127f790353c',
    title:
      'Staying Connected Today and in the Future: Behind the Technology of Neos',
    page_url:
      'staying-connected-today-and-in-the-future-behind-the-technology-of-neos',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=1500&height=844&format=webp&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=3000&height=1688&format=webp&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=6000&height=3376&format=webp&quality=50 6000w',
                  sizes: '(min-width: 6000px) 6000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=6000&height=3376&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=1500&height=844&format=jpg&quality=50 1500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=3000&height=1688&format=jpg&quality=50 3000w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt30c86e4802fd0e08/62b5e830b8bffb0fb08960a3/AdobeStock_436619173.jpeg?width=6000&height=3376&format=jpg&quality=50 6000w',
                sizes: '(min-width: 6000px) 6000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6000,
            height: 3376,
          },
        },
        image_alt_text: 'Future of the Office',
      },
    },
    headline:
      'Staying Connected Today and in the Future: Behind the Technology of Neos',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Now more than ever, it’s important to stay connected while working remotely. At a time when we need to come together, we’re being asked to maintain',
    customer: null,
    topics: [
      {
        display_name: 'Hybrid & Remote Work',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'May 14, 2020',
    id: '3f0fb73f-3edf-58d5-9792-325a01e2dc4c',
    uid: 'blt3b3f9861b4796392',
    title:
      'The Link Between Tech Adoption and Profits for Law Firms: Is Your Firm Leading or Trailing?',
    page_url:
      'the-link-between-tech-adoption-and-profits-for-law-firms-is-your-firm-leading-or-trailing',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=1250&height=700&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=2500&height=1400&format=webp&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=5000&height=2800&format=webp&quality=50 5000w',
                  sizes: '(min-width: 5000px) 5000px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=5000&height=2800&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=1250&height=700&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=2500&height=1400&format=jpg&quality=50 2500w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt32891ae20a52d66b/62b5e82480cd710f93e140e9/AdobeStock_108039455.jpeg?width=5000&height=2800&format=jpg&quality=50 5000w',
                sizes: '(min-width: 5000px) 5000px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5000,
            height: 2800,
          },
        },
        image_alt_text: 'Guy with graphic image of charts',
      },
    },
    headline:
      'The Link Between Tech Adoption and Profits for Law Firms: Is Your Firm Leading or Trailing?',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Increasing your firm’s technology adoption could help you become more competitive and profitable. In the 2019 Future Ready Lawyer Survey, Wolters Kluwer',
    customer: null,
    topics: [
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'April 22, 2020',
    id: '2a56c20e-123f-5a94-8d5f-00e783b2012e',
    uid: 'blt1bc487078c67bdaf',
    title:
      'Fewer Cars on the Road Will Be Temporary: Managing Today and Preparing for the Future',
    page_url:
      'fewer-cars-on-the-road-may-be-temporary-managing-today-and-preparing-for-the-future',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=1312&height=875&format=webp&quality=50 1312w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=2624&height=1750&format=webp&quality=50 2624w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=5248&height=3499&format=webp&quality=50 5248w',
                  sizes: '(min-width: 5248px) 5248px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=5248&height=3499&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=1312&height=875&format=jpg&quality=50 1312w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=2624&height=1750&format=jpg&quality=50 2624w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/bltbc014d019e87f81d/62b8eab94de82610183d7c0e/AdobeStock_91166937.jpeg?width=5248&height=3499&format=jpg&quality=50 5248w',
                sizes: '(min-width: 5248px) 5248px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5248,
            height: 3499,
          },
        },
        image_alt_text: 'Open road',
      },
    },
    headline:
      'Fewer Cars on the Road Will Be Temporary: Managing Today and Preparing for the Future',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      '"It should come as no surprise that traffic accidents have declined in the US since the COVID-19 pandemic began. There are several near-term impacts of having fewer cars on the road"',
    customer: null,
    topics: [
      {
        display_name: 'Legal Trends',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'March 17, 2020',
    id: '1b936317-9266-5ece-adb1-3c5805fecc73',
    uid: 'blte109e0d066921320',
    title: 'Managing Remote Work in Law Firms Amid COVID-19 Situation',
    page_url: 'managing-remote-work-in-law-firms-amid-covid-19-situation',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=1375&height=918&format=webp&quality=50 1375w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=2750&height=1835&format=webp&quality=50 2750w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=5500&height=3670&format=webp&quality=50 5500w',
                  sizes: '(min-width: 5500px) 5500px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=5500&height=3670&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=1375&height=918&format=jpg&quality=50 1375w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=2750&height=1835&format=jpg&quality=50 2750w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt36b896d1e5a4364b/62b8eac6a7c4490fd2ef0683/AdobeStock_327957823.jpeg?width=5500&height=3670&format=jpg&quality=50 5500w',
                sizes: '(min-width: 5500px) 5500px, 100vw',
              },
            },
            layout: 'constrained',
            width: 5500,
            height: 3670,
          },
        },
        image_alt_text: 'Man working from home',
      },
    },
    headline: 'Managing Remote Work in Law Firms Amid COVID-19 Situation',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'In response to the COVID-19 situation, your firm may already have plans in place to support remote work, but do you know how to access critical business',
    customer: null,
    topics: [
      {
        display_name: 'Hybrid & Remote Work',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'March 13, 2020',
    id: 'f868badf-4c02-5e45-9286-01e2d46d48ea',
    uid: 'blt7d4ea5ef4216fb47',
    title:
      'Assembly Software Announces Web-Based Case Management Platform, Neos',
    page_url:
      'assembly-software-announces-web-based-legal-case-management-platform-neos',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'Assembly Software Announces Web-Based Case Management Platform, Neos',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Assembly announces the release of Neos, a new, web-based case management platform, that dramatically advances the technology relied on by law firms to run their practices.',
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#2270DD',
      },
    },
  },
  {
    publication_date: 'February 05, 2019',
    id: 'de2afd5c-3aba-55bf-8377-d323d64fd007',
    uid: 'blt27cac5f2490069a5',
    title: 'Trialworks and Needles Announce the Formation of Assembly Software',
    page_url:
      'trialworks-and-needles-announce-the-formation-of-assembly-software',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline:
      'Trialworks and Needles Announce the Formation of Assembly Software',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'TrialWorks and Needles, the leading case management software companies for plaintiff lawyers, announce the formation of Assembly Software LLC. ',
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
      {
        display_name: 'Legal Tech',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#1A1A1A',
      },
    },
  },
  {
    publication_date: 'July 21, 2017',
    id: 'f677e986-9423-58ab-9692-44258b4b007f',
    uid: 'bltc8f7e9f9fac36ba4',
    title: 'Ridge Road Announces the Merger of Trialworks and Needles',
    page_url: 'ridge-announces-merger-of-trialworks-and-neos',
    key_quote: null,
    featured_image: {
      image: {
        image: null,
        image_alt_text: '',
      },
    },
    headline: 'Ridge Road Announces the Merger of Trialworks and Needles',
    lc_content_type: [
      {
        url_segment: 'newsroom',
        navigation_name: 'Newsroom',
        id: '50b816b2-b5ac-5ab4-afe0-7ac74c7a8fba',
      },
    ],
    teaser_text:
      'Ridge Road Partners announce the merger of TrialWorks and Needles, two of the most successful and trusted brands in the legal software industry. ',
    customer: null,
    topics: [
      {
        display_name: 'Press Releases',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly mark',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
  {
    publication_date: 'May 31, 2016',
    id: '2f654592-3e81-549c-a5d5-c9c0ec1b0ff7',
    uid: 'blta5cfcb046db60cf3',
    title: 'How To Identify Your Top and Worst Performers',
    page_url: 'identify-top-performers',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=1552&height=1120&format=webp&quality=50 1552w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=3104&height=2240&format=webp&quality=50 3104w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=6208&height=4480&format=webp&quality=50 6208w',
                  sizes: '(min-width: 6208px) 6208px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=6208&height=4480&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=1552&height=1120&format=jpg&quality=50 1552w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=3104&height=2240&format=jpg&quality=50 3104w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt11054b5aa0efd8f0/62a0c81ba9454a51970974c7/AdobeStock_494721143.jpeg?width=6208&height=4480&format=jpg&quality=50 6208w',
                sizes: '(min-width: 6208px) 6208px, 100vw',
              },
            },
            layout: 'constrained',
            width: 6208,
            height: 4480,
          },
        },
        image_alt_text: 'Woman on stairs',
      },
    },
    headline: 'How To Identify Your Top and Worst Performers',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      'Personnel is often the largest line item in any law firm’s budget. Your employees can be your greatest asset, but they can also be your biggest liability.',
    customer: null,
    topics: [
      {
        display_name: 'Automation & Efficiency',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly Mark',
      },
      card_color: {
        colors: '#737578',
      },
    },
  },
  {
    publication_date: 'April 11, 2016',
    id: '4815bca1-0432-52cb-9f28-4bf340c0441e',
    uid: 'blte23e856fe94ad5d1',
    title: 'Keeping Score with Law Firm Metrics and KPIs',
    page_url: 'importance-keeping-score-law-firm-kpi',
    key_quote: null,
    featured_image: {
      image: {
        image: {
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg',
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=1250&height=751&format=webp&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=2499&height=1501&format=webp&quality=50 2499w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=webp&quality=50 4998w',
                  sizes: '(min-width: 4998px) 4998px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=jpg&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=1250&height=751&format=jpg&quality=50 1250w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=2499&height=1501&format=jpg&quality=50 2499w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt9bbd95257cf68687/62b9edc84a5c4d0fcb1892e4/AdobeStock_417088312.jpeg?width=4998&height=3001&format=jpg&quality=50 4998w',
                sizes: '(min-width: 4998px) 4998px, 100vw',
              },
            },
            layout: 'constrained',
            width: 4998,
            height: 3001,
          },
        },
        image_alt_text: 'Black and blue chart graphic',
      },
    },
    headline: 'Keeping Score with Law Firm Metrics and KPIs',
    lc_content_type: [
      {
        url_segment: 'blogs',
        navigation_name: 'Blogs',
        id: '025c585e-7423-56aa-9156-49da3e44f89b',
      },
    ],
    teaser_text:
      '"If your law firm wants to stay ahead of the competition, you have to keep score with business metrics and key performance indicators (KPIs). Like in the"',
    customer: null,
    topics: [
      {
        display_name: 'Law Firm Marketing',
      },
    ],
    logo_card: {
      logo: {
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=webp&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=webp&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=webp&quality=50 640w',
                  sizes: '(min-width: 640px) 640px, 100vw',
                  type: 'image/webp',
                },
              ],
              fallback: {
                src: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50',
                srcSet:
                  'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=160&height=96&format=png&quality=50 160w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=320&height=192&format=png&quality=50 320w,\nhttps://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png?width=640&height=384&format=png&quality=50 640w',
                sizes: '(min-width: 640px) 640px, 100vw',
              },
            },
            layout: 'constrained',
            width: 640,
            height: 384,
          },
          url: 'https://images.contentstack.io/v3/assets/blt150f0e99f9258957/blt41f3d2f72613c9ef/62b5ba0a68cb740f8d90552a/assemblycard.png',
        },
        image_alt_text: 'Assembly logo',
      },
      card_color: {
        colors: '#061248',
      },
    },
  },
];
